//Objects
//Libraries
import * as moment from 'moment';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppointmentFactoryService } from './appointment-factory.service';
import { AppointmentRequest } from '../../requests/appointmentRequest';
import { DISPLAY_DATE_FORMAT } from '../../constants/app.constants';
import { ApiAppointmentService } from './api-appointment.service';
import { AppointmentValidators } from '../../validators/AppointmentValidators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "./appointment-factory.service";
import * as i3 from "./api-appointment.service";
var AppointmentFormService = /** @class */ (function () {
    function AppointmentFormService(fb, apptFactory, apiAppointmentsService) {
        this.fb = fb;
        this.apptFactory = apptFactory;
        this.apiAppointmentsService = apiAppointmentsService;
        this.patientAppointmentConfirm = new Subject();
        this.availableTimesDropDownList = new Array();
        this.appointmentDetails = new BehaviorSubject([]);
    }
    AppointmentFormService.prototype.getPatientAppointmentConfirm = function () {
        return this.patientAppointmentConfirm.asObservable();
    };
    AppointmentFormService.prototype.setPatientAppointmentConfirm = function () {
        this.patientAppointmentConfirm.next(true);
    };
    AppointmentFormService.prototype.getDoublePatientAppointmentMsgConfirm = function (date) {
        var confirmMsg = 'There is already an appointment registered for this patient on ' +
            date +
            '. Do you still want to proceed?';
        return confirm(confirmMsg);
    };
    AppointmentFormService.prototype.setAppointmentForApi = function (input, isReferral) {
        var appointment = input;
        if (input instanceof FormGroup) {
            appointment = this.apptFactory.createAppointment(input, 'FORMGROUP');
        }
        //
        var appointmentRequest = new AppointmentRequest(appointment);
        // Set doctor id, if any
        appointmentRequest.setDoctor(appointment.preferredDoctor);
        return appointmentRequest;
    };
    AppointmentFormService.prototype.validateAppointmentCreation = function (appointmentsFormGroup) {
        var date = moment(appointmentsFormGroup.get('appointmentDate').value).format(DISPLAY_DATE_FORMAT);
        var filter = {
            CLINIC_ID_LIST: [appointmentsFormGroup.get('clinicId').value],
        };
    };
    AppointmentFormService.prototype.getAvailableTimesDropDownList = function () {
        return this.availableTimesDropDownList;
    };
    AppointmentFormService.prototype.getWithin1HourBookingConfirm = function (visitDate) {
        var isWithin1Hour = this.within1HourBookingPeriod(visitDate);
        if (isWithin1Hour) {
            var confirmFwd = confirm('The current timeslot selected is within the 1 hour booking period. Are you sure you want to proceed?');
            if (confirmFwd) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return true;
        }
    };
    AppointmentFormService.prototype.within1HourBookingPeriod = function (apptDate) {
        var appointmentDate = apptDate.value;
        var bookingDate = new Date();
        var bookingPeriodInMinutes = 60;
        var userActionBufferTime = 0;
        var finalBookingPeriodInMinutes = bookingPeriodInMinutes - userActionBufferTime;
        var bookingNotValid = false;
        bookingNotValid =
            moment(appointmentDate).isAfter(bookingDate) &&
                (appointmentDate.getTime() - bookingDate.getTime()) / 60000 <
                    finalBookingPeriodInMinutes
                ? true
                : false;
        return bookingNotValid;
    };
    AppointmentFormService.prototype.createBlockedTimeFormGroup = function () {
        return this.fb.group({
            startDate: [new Date(), Validators.required],
            duration: [''],
            doctorId: ['', Validators.required],
            remarks: '',
        });
    };
    AppointmentFormService.prototype.clickedWithinCalendar = function (eventInCalendar) {
        return eventInCalendar !== null ? true : false;
    };
    AppointmentFormService.prototype.setMandatoryFields = function (appointmentForm) {
        var appointmentDate = appointmentForm.get('appointmentDate');
        var patientIdentifier = appointmentForm.get('patientIdentifier');
        var contactNumber = appointmentForm.get('contactNumber');
        var appointmentTime = appointmentForm.get('appointmentTime');
        var name = appointmentForm.get('name');
        var remarks = appointmentForm.get('remarks');
        this.setRequired(appointmentDate);
        this.setRequired(appointmentTime);
        this.setRequired(name);
        //  this.setRequired(remarks);
        this.setRequired(patientIdentifier);
        this.setRequired(contactNumber);
        patientIdentifier.setValidators(AppointmentValidators.validateSerialHi());
        contactNumber.setValidators(AppointmentValidators.validateValidateMobile());
    };
    AppointmentFormService.prototype.setMandatoryPassword = function (passwordForm) {
        var oldPassword = passwordForm.get('oldPassword');
        var newPassword = passwordForm.get('newPassword');
        this.setRequired(oldPassword);
        newPassword.setValidators(AppointmentValidators.validatePassword());
    };
    AppointmentFormService.prototype.setRequired = function (form) {
        form.setValidators(Validators.required);
    };
    AppointmentFormService.prototype.clearValidators = function (form) {
        form.clearValidators();
    };
    AppointmentFormService.prototype.setAppointmentDetails = function (formGroup) {
        this.appointmentDetails.next(formGroup);
    };
    AppointmentFormService.prototype.getAppointmentDetails = function () {
        return this.appointmentDetails.asObservable();
    };
    AppointmentFormService.prototype.appointmentIsExpired = function (visitDate) {
        var currentDate = new Date();
        var appointmentDate = visitDate.value;
        return moment(appointmentDate).isSameOrBefore(currentDate);
    };
    AppointmentFormService.prototype.withinAWindowPeriodFromCurrentTime = function (visitDate, minutes) {
        var currentDate = new Date();
        var appointmentDate = visitDate.value;
        return (moment(appointmentDate).isAfter(currentDate) &&
            (appointmentDate.getTime() - currentDate.getTime()) / 60000 < minutes);
    };
    AppointmentFormService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppointmentFormService_Factory() { return new AppointmentFormService(i0.ɵɵinject(i1.FormBuilder), i0.ɵɵinject(i2.AppointmentFactoryService), i0.ɵɵinject(i3.ApiAppointmentService)); }, token: AppointmentFormService, providedIn: "root" });
    return AppointmentFormService;
}());
export { AppointmentFormService };
